<template>
  <div class="mod-my-order">
    <amis-renderer :schema="schema" :on-action="onAction" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import { excelExport } from '@/utils';
import schema from './schemas/order.json';
import moment from 'moment';

// 隐藏手机号中间4位
function hideMobile(mobile) {
  if (mobile != null && mobile.length >= 11) {
    mobile = mobile.trim();
    return `${mobile.substring(
      mobile.length - 11,
      mobile.length - 8,
    )}****${mobile.substring(mobile.length - 4)}`;
  }
  return mobile;
}

// 订单
// noinspection JSUnresolvedVariable
const orderColumns = [
  { label: '订单编号', value: (item) => item.idText },
  { label: '微信昵称', value: (item) => item.nickname },
  { label: '取餐人', value: (item) => item.consignee },
  { label: '手机号', value: (item) => item.mobile },
  {
    label: '取餐人及手机号',
    value: (item) => `${item.consignee} ${item.mobile}`,
  },
  { label: '手机号（屏蔽4位）', value: (item) => hideMobile(item.mobile) },
  {
    label: '取餐人及手机号（屏蔽4位）',
    value: (item) => `${item.consignee} ${hideMobile(item.mobile)}`,
  },
  { label: '智能取餐柜编号', value: (item) => item.freezerSn },
  { label: '智能取餐柜名称', value: (item) => item.freezerName },
  { label: '时段全称', value: (item) => item.periodFullName },
  { label: '时段名称', value: (item) => item.periodName },
  { label: '时段起始时间', value: (item) => item.periodStartTime },
  { label: '时段结束时间', value: (item) => item.periodEndTime },
  { label: '是否统一配送', value: (item) => (item.isUnified ? '是' : '否') },
  { label: '取餐日期', value: (item) => item.pickupDate },
  { label: '订单类型', value: (item) => item.typeText },
  { label: '订单状态', value: (item) => item.stateText },
  { label: '取餐码', value: (item) => item.pickupCode },
  { label: '订单总价', value: (item) => item.totalPrice },
  { label: '实付金额', value: (item) => item.actualPrice },
  { label: '下单时间', value: (item) => item.createdAt },
  { label: '付款时间', value: (item) => item.paidAt },
  { label: '截单时间', value: (item) => item.cutOffTime },
  { label: '备注', value: (item) => item.remark },
  { label: '渠道组织名称', value: (item) => item.orgName },
];

// 配菜单
// noinspection JSUnresolvedVariable
const prepareItemsColumns = [
  { label: '供应商名称', value: (item) => item.orgName },
  { label: '智能取餐柜名称', value: (item) => item.freezerName },
  { label: '商品名称', value: (item) => item.goodsName },
  {
    label: '订单编号',
    value: (item) => ('000000000000' + item.orderId).slice(-12),
  },
  { label: '备注', value: (item) => item.remark },
];

// 配菜单统计
// noinspection JSUnresolvedVariable
const prepareStatColumns = [
  { label: '供应商名称', value: (item) => item.orgName },
  { label: '商品名称', value: (item) => item.goodsName },
  { label: '数量', value: (item) => item.quantity },
];

// 小票
// noinspection JSUnresolvedVariable
const receiptColumns = [
  ...orderColumns,
  { label: '商品编号', value: (item) => item.goods.sn },
  { label: '商品分类', value: (item) => item.goods.categoryName },
  { label: '商品名称', value: (item) => item.goods.name },
  { label: '是否套餐', value: (item) => (item.goods.isPackage ? '是' : '否') },
  { label: '套餐组合', value: (item) => item.goods.packageCombo },
  {
    label: '套餐组合（带价格）',
    value: (item) => item.goods.packageComboWithPrice,
  },
  {
    label: '商品全称',
    value: (item) =>
      item.goods.name +
      (item.goods.isPackage ? `：${item.goods.packageCombo}` : ''),
  },
  {
    label: '商品全称（带价格）',
    value: (item) =>
      item.goods.name +
      (item.goods.isPackage ? `：${item.goods.packageComboWithPrice}` : ''),
  },
  { label: '套餐固定价格', value: (item) => item.goods.packageFixedPrice },
  {
    label: '套餐搭配价格',
    value: (item) => item.goods.packageCollocationPrice,
  },
  { label: '商品总价', value: (item) => item.goods.totalPrice },
  { label: '商品数量', value: (item) => item.goods.quantity },
  { label: '仓位码', value: (item) => item.goods.spaceCode },
  // {
  //   label: '智能取餐柜地址',
  //   value: (item) => `${item.address} ${item.streetAddress}`,
  // },
];

// 点餐汇总
// noinspection JSUnresolvedVariable
const summaryColumns = [
  { label: '姓名', value: (item) => item.name },
  { label: '手机号', value: (item) => item.mobile },
  { label: '取餐人', value: (item) => item.consignee },
  { label: '取餐人手机号', value: (item) => item.consigneeMobile },
  { label: '订单总数', value: (item) => item.orderNum },
  { label: '订单总价', value: (item) => item.totalPrice },
  { label: '订单实付总价', value: (item) => item.actualPrice },
  { label: '团餐订单总数', value: (item) => item.groupOrderNum },
  {
    label: '团餐挂账',
    value: (item) => item.totalPrice - item.actualPrice || 0,
  },
];

export default {
  name: 'MyOrder',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
  methods: {
    // 按钮动作回调
    async onAction(e, action, ctx) {
      let filename, columns, items;
      if (action.actionType === 'export-order') {
        filename = `订单 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = orderColumns;
        items = ctx.items;
      } else if (action.actionType === 'export-prepare') {
        filename = `配餐单 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = prepareItemsColumns;
        const { data } = await this.$http({
          url: '/cc/order-goods/export-prepare',
          method: 'post',
          data: ctx.items.map((item) => item.id),
          loading: false,
        });
        items = data.data.items;
        this.onAction(
          null,
          { actionType: 'export-prepare-stat' },
          { items: data.data.stat },
        ).then();
      } else if (action.actionType === 'export-prepare-stat') {
        filename = `配餐统计 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = prepareStatColumns;
        items = ctx.items;
      } else if (action.actionType === 'export-receipt') {
        filename = `小票 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = receiptColumns;
        const { data } = await this.$http({
          url: '/cc/order-goods/export-receipt',
          method: 'post',
          data: ctx.items.map((item) => item.id),
          loading: false,
        });
        items = ctx.items.flatMap((item) => {
          const goodsList = data.data.map[item.id];
          if (!goodsList) {
            return [];
          }
          return goodsList.map((goods) => ({ ...item, goods }));
        });
      } else if (action.actionType === 'export-summary') {
        filename = `点餐汇总 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = summaryColumns;
        const { data } = await this.$http({
          url: '/cc/order/export-summary',
          method: 'post',
          data: ctx.items.map((item) => item.id),
          loading: false,
        });
        items = data.data.items;
      } else {
        return;
      }

      // 暂无数据
      if (!items || items.length === 0) {
        this.$message({
          message: '暂无数据',
          type: 'warning',
          duration: 1500,
        });
        return;
      }

      await excelExport(filename, columns, items);
    },
  },
};
</script>

<style lang="scss" scoped></style>
